import SyndData from "../../component/container/synd-data";
import Channels from "../../component/container/channels";
import Footer from "../footer/footer";

const TabProperty = (props) => {
  const isIframe = true;
  //sessionStorage.getItem("isIframe") != null ? (sessionStorage.getItem("isIframe") === "true") : false;
  
  return (
    <div>
      <SyndData propertyData={props.propertyData} companyName={props.companyName} mcUrl={props.mcUrl}/>
      { /*<div className="leads-container">
        <EmailLeads />
        <PhoneLeads />
  </div> */}
      { /*<div className="leads-container">
        <EmailChart />
        <PhoneChart />
</div> */}
      
      { 
        !props.totalLeadChannels?.iblcExist ? 
        <p class="r-text-center r-pt-24 r-pb-24">The Syndication Lead Channel is not enabled for this property. Please call Realpage support at 1 (800) 704-0154.</p> :
        <Channels channelList={props.channelList} isFromCompanyTab = {props.isFromCompanyTab} companyName={props.companyName} property={props.propertyData} countryCode={props.propertyData.countryCode} totalLeadChannels={props.totalLeadChannels} />
      }

      { !isIframe &&
        <Footer />
      }
    </div>
  );
};

export default TabProperty;